(() => {
	let nav,
		navButton,
		waves,
		lastScrollPosition = 0,
		ticking = false,
		animationFrame;
	const minWidth = 600;

	const updateWaves = () => {
		const scrollTop = lastScrollPosition / 0.5;
		for (let i = 0; i < waves.length; i++) {
			waves[i].style.backgroundPositionX = `${(i % 2 ? -1 : 1) * scrollTop}px`;
		}
		ticking = false;
	};

	const handleScroll = () => {
		lastScrollPosition = window.pageYOffset;
		if (!ticking && window.innerWidth >= minWidth) {
			ticking = true;
			animationFrame = requestAnimationFrame(updateWaves);
		}
	};

	const toggleNavigation = () => {
		nav.classList.toggle("navigation--open");
		navButton.classList.toggle("nav-button--open");
	};

	const init = () => {
		nav = document.getElementById("navigation");
		navButton = document.getElementById("nav-button");
		waves = document.querySelectorAll(".section--waves .section__waves");

		window.addEventListener("scroll", handleScroll, { passive: true });
		navButton.addEventListener("click", toggleNavigation);
	};

	if (document.readyState === "loading") {
		document.addEventListener("DOMContentLoaded", init);
	} else {
		init();
	}
})();
